import React, {useState} from 'react';
import {
  Form, theme, ConfigProvider, Space, Result, Button
} from 'antd';
import Axios from 'axios';
import moment from 'moment';
import { useViewport } from '../Components/Viewport';
import QuoteForm from '../Components/Form/QuoteForm';
import { FormTheme } from '../Components/Form/FormTheme';
import beemascaffold from '../Images/beemascaffold.jpeg'
import beemascaffold2 from '../Images/beemascaffold2.jpeg'

const GetQuote = () => {
  const [form] = Form.useForm();
  const [isPurchaseDateDisabled, setPurchaseDateDisabled] = useState(false);
  const [isRentalDateDisabled, setRentalDateDisabled] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [isPurchase, togglePurchase] = useState(true);
  const [isSingleWidth, selectSingleWidth] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [isSuccess, setSuccess] = useState(true);
  const { screenWidth, screenHeight } = useViewport();
  const breakpoint = 880;

  const toggleWidth = (e) => {
    if((e.target.value) === 'double') {
      selectSingleWidth(false);
    } else {
      selectSingleWidth(true);
    }
  };
  const onEquiryRadioChange = (e) => {
    const value = e.target.value;
    if (value === 'purchase') {
      togglePurchase(true);
    } else {
      togglePurchase(false);
    }
  };

  const onCheckboxPurchaseChange = () => {
    setPurchaseDateDisabled(!isPurchaseDateDisabled)
    setRentalDateDisabled(isRentalDateDisabled)
    if(!isPurchaseDateDisabled) {
      form.setFields([
        {
          name: "datePurchase", // required
          value: undefined, //optional
          errors: undefined,
        },
      ]);
    }
  };

  const onCheckboxRentalChange = (e) => {
    setRentalDateDisabled(!isRentalDateDisabled)
    setPurchaseDateDisabled(isPurchaseDateDisabled)
    if(!isRentalDateDisabled) {
      form.setFields([
        {
          name: "dateRental", // required
          value: undefined, //optional
          errors: undefined,
        },
      ]);
    }
  };

  const convertDatetoString = (date) => (
    moment(date).format('DD-MM-YYYY').toString()
  )

  const onFinish = (values) => {
    setLoadings(true);
    sendEmail(values);

  };

  const toggleShowResult = () => (
    setShowResult(false)
  );

  const sendEmail = (formData) => {
    let dateRentalArr = [];
    let purchaseDateStr = '';
    let purchaseCheckboxBool = 'no';
    
    if (typeof formData.dateRental !== 'undefined') {
      dateRentalArr = [convertDatetoString(formData.dateRental[0]?.$d), convertDatetoString(formData?.dateRental[1]?.$d)]
    }
    if (typeof formData.datePurchase !== 'undefined') {
      purchaseDateStr = convertDatetoString(formData.datePurchase?.$d);
    }
    if (formData.enquiryType === 'purchase') {
      purchaseCheckboxBool = (isPurchaseDateDisabled) ? 'yes' : 'no';
    }
    const currTimestamp = moment().format('DD-MM-YYYY HH:mm:ss').toString();
    const formDataFormatted = {
      ...formData,
      message : formData?.message ?? 'NA',
      companyName: formData?.companyName ?? 'NA',
      datePurchase: purchaseDateStr || 'NA',
      dateRental: dateRentalArr || 'NA',
      purchaseCheckbox: purchaseCheckboxBool ?? 'NA',
      dateDuration: formData?.dateDuration ?? 'NA',
      durationType: formData?.durationType ?? 'NA',
      timestamp: currTimestamp
    }
    Axios.post(
      'https://us-central1-beemascaffoldingsg.cloudfunctions.net/submit',
      formDataFormatted
    )
      .then(res => {
        form.resetFields();
        setLoadings(false);
        if (res.status === 200) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
        setShowResult(true);
      })
      .catch(error => {
        setLoadings(false);
        setSuccess(false);
        setShowResult(true);
      })
  }
  return (
    <div 
      style={{
        backgroundImage: (screenWidth > 1035) ? `url(${beemascaffold})` : `url(${beemascaffold2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: (screenWidth > 1035) ? screenHeight-150 : screenHeight-100,
      }} >
      <div style={{fontSize: '2em', fontWeight: 900, padding: '20px'}}>
        Get Free Quote
      </div>
      <div>
        <ConfigProvider
          theme={{
            algorithm: [
              theme.darkAlgorithm,
              // theme.compactAlgorithm
            ],
            ...FormTheme
          }} >
          <Space 
            style={{
              padding: '35px 50px 0 50px',
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderRadius: "10px",
            }}>
            {(!showResult) ?
              <QuoteForm
                form={form}
                isPurchaseDateDisabled={isPurchaseDateDisabled}
                isRentalDateDisabled={isRentalDateDisabled}
                loadings={loadings}
                isPurchase={isPurchase}
                isSingleWidth={isSingleWidth}
                screenWidth={screenWidth}
                breakpoint={breakpoint}
                toggleWidth={toggleWidth}
                onEquiryRadioChange={onEquiryRadioChange}
                onCheckboxPurchaseChange={onCheckboxPurchaseChange}
                onCheckboxRentalChange={onCheckboxRentalChange}
                onFinish={onFinish} /> :
              (isSuccess) ?
                <Result
                  status="success"
                  title="We've received your request!"
                  subTitle="We do our best to respond in 1 business day"
                  extra={[
                    <Button type="primary" key="request" onClick={toggleShowResult}>
                      Submit another request
                    </Button>,
                  ]}
                /> :
                <Result
                  status="error"
                  title="Submission Failed"
                  subTitle="Please refresh the page and try again"
                  extra={[]}
                />
            }

          </Space>
        </ConfigProvider>
      </div>
    </div>
  );
};
export default GetQuote;
