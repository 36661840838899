import React, {useState} from 'react';
import { Card } from 'antd';

const { Meta } = Card;

const Product = ({bgImg, title, blurImg}) => {
  const [highlight, setHighlight] = useState(false)
  return (
    
    <Card
      style={{overflow: 'hidden', cursor: 'auto', flex: '1 0 250px', boxShadow: '0px 0px 7px #00000034', margin: '1rem',
      transition: 'all 250ms ease-in-out'}}
      hoverable
      bordered={true}
      onMouseEnter={() => setHighlight(true)}
      onMouseLeave={() => setHighlight(false)}
      cover={<div style={{padding: '10px', borderRadius: '10px', backgroundColor: '#eeece6'}}>
      <div style={{
          borderRadius: '100%',
          backgroundColor: 'black',
          backgroundImage: `url(${bgImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          marginRight: 'auto',
          marginLeft: 'auto',
          width: '170px',
          height: '170px',
          textAlign: 'center',
          verticalAlign: 'middle',}} >
        {(blurImg) ?
          <div className='productFilter'
            style={(highlight) ? {backgroundColor: 'rgba(0, 0, 0, 0)'} : {backgroundColor: 'rgba(255, 255, 255, 0.3'}}  >
          </div> :
          <></>}
        </div>
      </div>} >
      <Meta title={title} />
    </Card>
    
  );
}

export default Product;