import { NavLink, useLocation } from "react-router-dom";
import React from 'react';
import { Menu } from 'antd';
import '../../App.css';

const RightMenu = ({type, layout, onClose}) => {
  const { pathname } = useLocation();
  const base = `/${pathname.slice(1).split("/").shift()}`;
  console.log(pathname)
  console.log(base)
  return (
    <Menu mode={type} defaultSelectedKeys={base} style={(layout==='desktop')?
    {
      width: '100%'
    }:{
      minWidth: '100%'
    }} className="right-menu" >
      <Menu.Item className="rightMenuTxt" onClick={onClose} key="/">
        <NavLink to="/">HOME</NavLink>
      </Menu.Item>
      <Menu.Item className="rightMenuTxt" onClick={onClose} key="/about">
        <NavLink to="/about">ABOUT US</NavLink>
      </Menu.Item>
      <Menu.Item className="rightMenuTxt" onClick={onClose} key="/contact">
        <NavLink to="/contact">CONTACT US</NavLink>
      </Menu.Item>
      <Menu.Item className="quoteBtn" onClick={onClose} key="/getQuote">
        <NavLink to="/getQuote">Get Quote</NavLink>
      </Menu.Item>
    </Menu>
  );
}
export default RightMenu;