import React  from 'react';
import { theme, Button, Modal, Form, ConfigProvider } from 'antd';
import { FormTheme } from '../Components/Form/FormTheme';
import CustomiseInput from './Form/CustomiseInput';

const CatalogueModal = ({
    open,
    confirmLoading,
    handleOk,
    form,
    handleCancel
}) => {
  
  
  return (
    <>
      <ConfigProvider
          theme={{
              algorithm: [
              theme.darkAlgorithm,
              // theme.compactAlgorithm
              ],
              ...FormTheme
        }} >
        <Modal
          title="Download Catalogue"
          open={open}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={[        

            ]}
        >
          <div style={{ width: '90%', marginLeft: "auto", marginRight: "auto",  padding: '15px'}} >
            <Form
              labelWrap
              form={form}
              name="control-hooks"
              onFinish={handleOk} >

            <CustomiseInput />
            <Button style={{width: '150px'}} type="primary" htmlType="submit" loading={confirmLoading}>
              {/* <a
                href={BeemaScaffoldingSGCatalogue}
                download="cataloguePDF"
                target="_blank"
                rel="noreferrer" > */}
                Download
              {/* </a> */}
            </Button> 
            </Form>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};
export default CatalogueModal;