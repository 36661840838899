import React from 'react';
import moment from 'moment';
import {
  Form, InputNumber, Radio,
  DatePicker, Row, Col,
  Checkbox, Select
} from 'antd';
import FormItem from 'antd/es/form/FormItem';

const { RangePicker } = DatePicker;
const { Option } = Select;

const EnquiryType = ({
  isRentalDateDisabled,
  isPurchaseDateDisabled,
  onCheckboxRentalChange,
  onCheckboxPurchaseChange,
  isPurchase,
  onEquiryRadioChange
}) => {

  const disabledDate = (current) => {
    const customDate = moment().format("YYYY-MM-DD");
    return current.subtract(7, 'd') && current.subtract(7, 'd') < moment(customDate, "YYYY-MM-DD");
  };


  const selectAfterDuration = (
    <FormItem style={{margin: 0, padding: 0}}
      name="durationType" >
      <Select style={{margin: 0, padding: 0}} >
        <Option value="day">day(s)</Option>
        <Option value="week">week(s)</Option>
        <Option value="month">month(s)</Option>
      </Select>
    </FormItem>

  );

  return (
    <>
      <Form.Item 
        style={{width: "100%", marginBottom: '-10px'}}
        label="Select enquiry type:"
        name="enquiryType" >
        <Radio.Group onChange={onEquiryRadioChange} style={{width: "100%"}} >
          <Radio.Button style={(isPurchase)?{ fontWeight: 800, width: '50%', borderRadius: '6px 0px 0px 0px' }:{ width: '50%', borderRadius: '6px 0px 0px 0px' }} value="purchase" >Purchase</Radio.Button>
          <Radio.Button style={(!isPurchase)?{ fontWeight: 800, width: '50%', borderRadius: '0px 6px 0px 0px' }:{ width: '50%', borderRadius: '0px 6px 0px 0px' }} value="rental" >Rental</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <div style={{ width: '100%', border: '1px dashed #a1a1a1', borderRadius: '0px 0px 6px 6px', padding: '30px 7px 0px 7px'}}>
        {(isPurchase) ?
          <Form.Item 
            style={{marginBottom: '-10px'}} >
            <Row span={24}>
              <Col span={12}>
              <Form.Item
                name="datePurchase"
                style={{width: "100%"}}
                rules={[
                  {
                    required: !isPurchaseDateDisabled,
                    message: 'Please pick a delivery date',
                  },
                ]}
              >
                <DatePicker style={{ width: "90%", float:'left' }}
                  disabledDate={disabledDate}
                  placeholder="Delivery date"
                  disabled={isPurchaseDateDisabled}
                />
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
                name="purchaseCheckbox"
              >
                <Checkbox style={{float: "right", width: "100%"}} 
                  checked={isPurchaseDateDisabled}
                  onChange={onCheckboxPurchaseChange}>
                  I don't have a delivery date
                </Checkbox>
              </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          :
          <Form.Item style={{marginBottom: '-10px'}}>
            <Row span={24}>
              <Col span={14}>
                { (!isRentalDateDisabled) ? 
                  <Form.Item
                    name="dateRental"
                    style={{width: "95%"}}
                    rules={[
                      {
                        required: !isRentalDateDisabled,
                        message: 'Please pick a rental date',
                      },
                    ]}
                  >
                    <RangePicker 
                      disabled={isRentalDateDisabled} 
                      disabledDate={disabledDate} />
                  </Form.Item>
                  : <Form.Item
                      name="dateDuration"
                      style={{width: "95%"}}
                      rules={[
                        {
                          required: isRentalDateDisabled,
                          message: 'Please enter the rental duration',
                        },
                      ]} >
                        <InputNumber style={{width: '100%', float: "left"}} placeholder="e.g. 3" min={1} addonAfter={selectAfterDuration} />
                  </Form.Item>
              }
              </Col>
              <Col span={10}>
                <Form.Item>
                  <Checkbox style={{float: "right", width: "100%"}}
                    checked={isRentalDateDisabled}
                    onChange={onCheckboxRentalChange}>
                    I don't have a fixed date
                  </Checkbox>
                </Form.Item>
              </Col>
            
            </Row>
          </Form.Item>
        }
      </div>
    </>
  )
}

export default EnquiryType