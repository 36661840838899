import React from 'react';
import { Col, Row } from 'antd';
import singlewidthImg from '../Images/single_width_tower.jpeg'
import doublewidthImg from '../Images/double_width_tower.jpeg'
import Product from './Product';

const Products = ({screenWidth, breakpoint}) => {

  const title1 = () => (
    <>
      <span style={{fontWeight: '700'}}>Single Width Tower</span>
      <span style={{fontWeight: '300'}}>
        <Row type="flex" justify="center">
          <Col flex="100%">
            <u>Length x Width:</u>
          </Col>
          <Col flex="auto">
            2 meter x 0.80 meter
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col flex="100%">
            <u>Platform Height:</u>
          </Col>
          <Col flex="auto">
            2 meter to 6 meter
          </Col>
        </Row>
      </span>
    </>
  )
  const title2 = () => (
    <>
      <span style={{fontWeight: '700'}}>Double Width Tower</span>
      <span style={{fontWeight: '300'}}>
        <Row type="flex" justify="center">
          <Col flex="100%">
            <u>Length x Width:</u>
          </Col>
          <Col flex="70%">
            2 meter x 1.35 meter
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col flex="100%">
            <u>Platform Height:</u>
          </Col>
          <Col flex="70%">
            2 meter to 12 meter
          </Col>
        </Row>
      </span>
    </>
  )

  const contentList = [
    {
      Title: title1(),
      Img: singlewidthImg,
    },
    {
      Title: title2(),
      Img: doublewidthImg,
    },
  ];
  const isDesktopVersion = (screenWidth > breakpoint)
  return (
    <>
    {/* {(screenWidth > breakpoint) ? */}
      <div className="productsContainer" >
        {contentList.map((contentObj) => (
          <Product blurImg={(isDesktopVersion) ? true : false} bgImg={contentObj.Img} title={contentObj.Title} />
        ))}
      </div>
       {/* :
      
        <>
          <Row style={{width: '100%'}} span={18} justify="center">
            <Product blurImg={false} bgImg={singlewidth} title={title1()} />
          </Row>
          <Row style={{width: '100%'}} span={18} justify="center">
            <Product blurImg={false} bgImg={doublewidthImg} title={title2()} />
          </Row>
          <Row style={{width: '100%'}} span={18} justify="center">
            <Product blurImg={false} bgImg={heightImg} title={title3()} />
          </Row>
        </>
      } */}
    </>
  );
}

export default Products;