import React, {useState} from 'react';
import Service from './Service';
import { Col, Row } from 'antd';
import installImg from '../../Images/install_img.jpg'
import rentalImg from '../../Images/rental_img.jpg'
import salesImg from '../../Images/sales_img.jpg'

const Services = ({screenWidth, breakpoint}) => {
  return (
    <div style={{padding: '20px', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
      <Row span={screenWidth < breakpoint ? 24 : 18} justify="center">
        <Col span={screenWidth < breakpoint ? 8 : 6}>
          <Service screenWidth={screenWidth} breakpoint={breakpoint} img={salesImg} title="Sales" />
        </Col>
        <Col span={screenWidth < breakpoint ? 8 : 6}>
          <Service screenWidth={screenWidth} breakpoint={breakpoint} img={rentalImg} title="Rental" />
        </Col>
        <Col span={screenWidth < breakpoint ? 8 : 6}>
          <Service screenWidth={screenWidth} breakpoint={breakpoint} img={installImg} title="Install &amp; Dismantle" />
        </Col>
      </Row>
    </div>
  );
}
export default Services;