import React from 'react';
import { Col, Row } from 'antd';
import { useViewport } from '../Components/Viewport';
import aboutusImg from '../Images/aboutus_img.jpeg'

const AboutUs = () => {
  const {
    screenWidth,
    // screenHeight 
  } = useViewport();
  const breakpoint = 1035;

  const visionContent = "Beema Scaffolding Singapore envisions a safer, "
    + "stronger future. Named after our grandmother, Beema, "
    + "our aluminium mobile scaffolding solutions embody her resilience. Committed "
    + "to safety and durability, we proudly serve Singapore, "
    + "ensuring every project soars with trust and quality."

    const missionContent = "At Beema Scaffolding Singapore, our mission is to "
    + "elevate safety, innovation, and reliability in every project "
    + "we touch. Rooted in a legacy of honor and resilience, "
    + "we are committed to providing the highest quality "
    + "scaffold solutions that safeguard the well-being of our "
    + "workforce and partners. Our focus on durability and "
    + "precision ensures that our scaffolds stand strong, "
    + "supporting the growth and success of our clients' "
    + "endeavors. With a dedication to excellence and a heart "
    + "for service, we strive to build bridges of trust and "
    + "empowerment, creating a safer and more productive "
    + "future for our community and beyond."
  
  return (
    <div style={{color: "black"}}>
      <h1>About Us</h1>
      {(screenWidth > breakpoint) ?
        <Row justify="center" span={22}>
          <Col span={11}>
            <div style={{
              backgroundImage: `url(${aboutusImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '85%',
              height: '100%',
              borderRadius: '10px'
            }}>

            </div>
          </Col>
          <Col span={11}>
            <div style={{fontSize: '20px', color: '#a20', padding: '15px'}}><b>VISION</b></div>
            <div style={{fontSize: '15px', textAlign: 'left'}}>{visionContent}</div>
            <div style={{fontSize: '20px', color: '#a20', padding: '15px'}}><b>MISSION</b></div>
            <div style={{fontSize: '15px', textAlign: 'left'}}>{missionContent}</div>
          </Col>
        </Row> :
        <>
        <Row justify="center" span={24}>
          <Col span={24}>
            <div style={{
              backgroundImage: `url(${aboutusImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
              height: '20vh'
            }}>
          <div
            style={ { height: '100%',
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.459)',
              backgroundImage: 'linear-gradient(#f4f5f4, rgba(0, 0, 0, 0.2))'}}  >
          </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" span={22}>
          <Col span={22}>
            <div style={{fontSize: '20px', color: '#a20', padding: '13px'}}><b>VISION</b></div>
            <div style={{fontSize: '15px', textAlign: 'justify'}}>{visionContent}</div>
            <div style={{fontSize: '20px', color: '#a20', padding: '13px'}}><b>MISSION</b></div>
            <div style={{fontSize: '15px', textAlign: 'justify'}}>{missionContent}</div>
          </Col>
        </Row>
        </>
      }
    </div>
  )
};

export default AboutUs;