import React from 'react';
import {
  Button, Form, InputNumber,
  Radio, Row, Col, Tooltip, Popover
} from 'antd';
import { PlusOutlined, CloseCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';

const ProductDetailsMobile = ({ isSingleWidth, toggleWidth }) => {
  const content = (
    <>
      Single width: 0.80m
      <br />
      Double width: 1.35m
    </>
  );
  return (
    <>
      <Form.Item
        label="Select product details:"
        style={{marginBottom: 0, width: '100%', marginTop: '10px'}}
        rules={[
        ]}
        >
        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} style={{border: '1px dashed #a1a1a1', borderBottom: 0, marginBottom: '0', padding: '25px 10px 1px 10px', borderRadius: '6px 6px 0px 0px'}}>
                  {
                    (key === 0)
                      ? 
                      <Tooltip title="">
                        <CloseCircleOutlined style={{ float: 'right', color: 'grey', fontSize: "18px", cursor: 'not-allowed', marginTop: '-21px', marginRight: '-7px', paddingBottom: '10px'}} />
                      </Tooltip>
                      :
                      <Tooltip title="delete product">
                        <CloseCircleOutlined style={{ float: 'right', color: 'white', fontSize: "18px", marginTop: '-21px', marginRight: '-7px'}} onClick={() => remove(name)} />
                      </Tooltip>
                  }
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={5}><label >Working height:</label></Col>
                    <Col span={18}>
                  <Form.Item
                    {...restField}
                    name={[name, 'height']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing height',
                      },
                    ]}
                  >
                    
                    <InputNumber
                      step="0.1"
                      min={2} max={15}
                      placeholder="e.g. 3.5"
                      addonAfter="meter" />
                  </Form.Item>
                  </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={6}>
                      <label>
                        Width&nbsp;
                        <Popover content={content} title="">
                          <InfoCircleOutlined />
                        </Popover>
                      </label>
                    </Col>
                    <Col span={18}>
                      <Form.Item
                        {...restField}
                        name={[name, 'width']}
                        rules={[{ required: true, message: 'Missing width' }]}
                      >
                        
                        <Radio.Group>
                          <Radio.Button 
                            style={{}
                              // (isSingleWidth)
                              //   ?{ fontWeight: '600'}
                              //   :{}
                            }
                            onClick={toggleWidth}
                            value="single">
                            Single
                          </Radio.Button>
                          <Radio.Button
                            style={{}
                              // (!isSingleWidth)
                              //   ?{fontWeight: '600'}
                              //   :{}
                            }
                            onClick={toggleWidth}
                            value="double">
                            Double
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={5}><label>Quantity: </label></Col>
                    <Col span={19}>
                  <Form.Item
                    {...restField}
                    rules={[{ required: true, message: 'Missing Quantity' }]}

                    name={[name, 'quantity']}
                  >
                      <InputNumber  placeholder={'e.g. 1'}  min={1} />
                  </Form.Item>
                  </Col>
                  </Row>
                </div>
              ))}
              <Form.Item>
                <Button  onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Product
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  )
}
export default ProductDetailsMobile;