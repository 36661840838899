import React from 'react';

const Service = ({img, title, screenWidth, breakpoint}) => {
  const widthS = (screenWidth < breakpoint) ? '60px' : '120px';
  const heightS = (screenWidth < breakpoint) ? '75px' : '150px';
  return (
    <>
      <div style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: widthS,
        height: heightS,
        marginRight: 'auto',
        marginLeft: 'auto',
      }}>
        
      </div>
      <div style={{color: '#a20000', fontSize: '17px', padding: '10px 0px 10px 0px'}}><b>{title}</b></div>
    </>
  );
}
export default Service;