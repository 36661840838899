import React from 'react';
import {
  Button, Form, Input, Row, Col,
} from 'antd';
import CustomiseInput from './CustomiseInput';
import EnquiryType from './EnquiryType';
import ProductDetailsDesktop from './ProductDetailsDesktop';
import ProductDetailsMobile from './ProductDetailsMobile';

const initialValues = {
  products: [
    {
      height: undefined,
      quantity: undefined,
      width: 'single',
    }
  ],
  enquiryType: 'purchase',
  durationType: 'week',
};

const encodedUrl = () => (`http://wa.me/${encodeURIComponent('+6585123494')}?`
    + `text=${'Hello, I\'m interested to know more about your products'}`)

const QuoteForm = ({
  form,
  isPurchaseDateDisabled,
  isRentalDateDisabled,
  loadings,
  isPurchase,
  isSingleWidth,
  screenWidth,
  breakpoint,
  toggleWidth,
  onEquiryRadioChange,
  onCheckboxPurchaseChange,
  onCheckboxRentalChange,
  onFinish
}) => {

  return (
    <Form
      initialValues={initialValues}
      form={form}
      labelWrap
      layout={(screenWidth > breakpoint) ? 'vertical' : 'vertical'}
      name="control-hooks"
      onFinish={onFinish}
      style={(screenWidth > breakpoint) ? 
        {width: "800px"} : {maxWidth: "400px"}}
    >
      <div style={{paddingBottom: '10px'}}>
        <i>For urgent enquiries, drop a What'sApp message to <a href={encodedUrl()}>+6585123494</a>.</i>
      </div>
      {(screenWidth > breakpoint) ? 
      <Row justify="space-between">
        <Col span={10}>
          <CustomiseInput />
          <Form.Item
            name="message"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Message (optional)" />
          </Form.Item>
        </Col>
        <Col span={13}>
          <EnquiryType
            onCheckboxPurchaseChange={onCheckboxPurchaseChange}
            onCheckboxRentalChange={onCheckboxRentalChange}
            isPurchaseDateDisabled={isPurchaseDateDisabled}
            isRentalDateDisabled={isRentalDateDisabled}
            onEquiryRadioChange={onEquiryRadioChange}
            isPurchase={isPurchase} />
          <ProductDetailsDesktop 
            isSingleWidth={isSingleWidth}
            toggleWidth={toggleWidth} />
        </Col>
      </Row>
      : 
        <Row>
          <CustomiseInput />
          <EnquiryType
            onCheckboxPurchaseChange={onCheckboxPurchaseChange}
            onCheckboxRentalChange={onCheckboxRentalChange}
            isPurchaseDateDisabled={isPurchaseDateDisabled}
            isRentalDateDisabled={isRentalDateDisabled}
            onEquiryRadioChange={onEquiryRadioChange}
            isPurchase={isPurchase} />
          <ProductDetailsMobile
            isSingleWidth={isSingleWidth}
            toggleWidth={toggleWidth} />
          <Form.Item
            style={{width: '100%'}}
            name="message"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={3} placeholder="Message (optional)" />
          </Form.Item>
        </Row> }
      <Form.Item>
        <Button style={{width: '150px'}} type="primary" htmlType="submit" loading={loadings}>
          Submit
        </Button>
        {/* <Button htmlType="button" onClick={onReset}>
          Reset
        </Button> */}
      </Form.Item>
    </Form>
  );
};
export default QuoteForm;
