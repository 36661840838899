export const FormTheme = {

  token: {
    colorPrimary: '#e68c85',
    colorError: '#fa4b00',
    colorText: "#fff",
    colorBorder: "#a1a1a1",
    colorTextPlaceholder: "#a1a1a1",
  },
  components: {
    Button: {
      colorPrimary: '#a20',
      colorBorder: "#a1a1a1",
      algorithm: true, // Enable algorithm
    },
    Input: {
      colorPrimary: '#e68c85',
      colorError: '#fa4b00',
      colorText: "#fff",
      colorBorder: "#a1a1a1",
      colorTextPlaceholder: "#a1a1a1",
      algorithm: true, // Enable algorithm
    },
    Radio: {
      colorPrimary: '#e68c85',
      colorBorder: "#a1a1a1",
      colorText: "#919090",
      colorPrimaryHover: "#a20",
      colorPrimaryActive: '#a20',
      algorithm: true, // Enable algorithm
    },
    InputNumber: {
      colorPrimary: '#e68c85',
      colorBorder: "#a1a1a1",
      colorText: "#fff",
      colorTextPlaceholder: "#a1a1a1",
      algorithm: true, // Enable algorithm
    },
    DatePicker: {
    },
  },
}