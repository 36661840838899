import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useViewport } from '../Viewport';
import { contentList } from './KeyPointsContent';
import { DownOutlined } from '@ant-design/icons';
import './KeyPoints.css'

const KeyPoints = () => {
  const { screenWidth } = useViewport();
  const breakpoint = 650;
  const keyPointHighlight = {
    color: '#a20000',
    border: '2px solid #a20000',
    borderRadius: '5px',
  }
  const keyPointHighlightMbl = {
    boxShadow: '0 0 3px #00000096',
    borderRadius: '5px',
    backgroundColor: '#d1b7b7',
    display: 'flex'
  }
  const [content, setContent] = useState(contentList[0]);

  const contentDisplayDesktop = () => (
    contentList.map((contentObj) => (
      (content.Content === contentObj.Content) ? 
      <Row justify="center">
        <Col className="KeyPointDescription"  span={20}>
          <div>
            <h2>{contentObj.Title}</h2>
            <p style={{textAlign:"justify", fontSize: '15px'}}>{contentObj.Content}</p>
          </div>
        </Col>
      </Row>
      : ''
    ))
  )

  return (
    <>
      {((screenWidth < breakpoint)) ?
      <>
        <Row span={24} align="middle" justify="center">
          {
            contentList.map((contentObj) => (
              <>
                <Col  className="keyPointBtnMbl"
                  style={(content.Content === contentObj.Content) ? keyPointHighlightMbl : {display: 'flex'}}
                  span={20}
                  onMouseEnter={() => setContent(contentObj)} >
                    <div style={{flex: '20%'}}> {contentObj.IconMbl} </div>
                    <div style={{flex: '70%', textAlign: 'left'}}> {contentObj.Title} </div>
                    <div style={{flex: '10%'}}>
                    {(content.Content !== contentObj.Content) ? <DownOutlined /> : <></>}
                    </div>
                </Col>
                {((content.Content === contentObj.Content) ?
                  <Col className="KeyPointDescription" style={{textAlign:"justify"}} span={20}>
                    <div>
                      <p>{contentObj.Content}</p>
                    </div>
                  </Col>
                : '')}
              </>
            ))
          }
        </Row>
      </> :
      <>
        <Row justify="center">
          {
            contentList.map((contentObj) => (
              <>
                <Col className="keyPointBtn"
                  style={(content.Content === contentObj.Content) ? keyPointHighlight : {}}
                  span={4}
                  onMouseEnter={() => setContent(contentObj)} >
                    <div> {contentObj.Title} </div>
                    <div>{contentObj.Icon} </div>
                </Col>
              </>
            ))
          }
        </Row> 
        {contentDisplayDesktop()}
      </>
      }
    </>
  )
}
export default KeyPoints;
