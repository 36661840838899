import React from 'react';
import { Link } from "react-router-dom";
import { DownloadOutlined } from '@ant-design/icons';
import { Col, Row, ConfigProvider } from 'antd';

const MoreSection = ({screenWidth, breakpoint, showModal}) => {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: '#fff',
          colorLinkActive: 'black',
          colorLinkHover: 'black'
        },
      }}
    >
    {(screenWidth > breakpoint) ?
    <Row justify="center" style={{alignItems: 'center'}}>
      <Col>
        <div onClick={showModal} className="quoteBtn" style={{margin: "20px", padding: '10px 0px 10px 0px', width: '250px', cursor: 'pointer'}} >
          <DownloadOutlined style={{paddingRight: '10px'}} />Download Catalogue
        </div>
      </Col>
      <Col >
          <Link to="/getQuote">
            <div className="quoteBtn" style={{margin: "20px", padding: '10px 0px 10px 0px', width: '250px'}} >
              Get Free Quote
            </div>
          </Link>
      </Col>
    </Row> :
    <>
      <Row justify="center" style={{alignItems: 'center'}}>
        <Col className="quoteBtn" onClick={showModal}
          style={{margin: "20px", padding: '10px 0px 10px 0px', width: '80%', cursor: 'pointer'}} 
          >
            <DownloadOutlined style={{paddingRight: '10px'}} />Download Catalogue
            
        </Col>
        
      </Row>
      
      <Link to="/getQuote">
      <Row justify="center" style={{alignItems: 'center'}}>
      
        <Col className="quoteBtn" 
          style={{margin: "20px", padding: '10px 0px 10px 0px', width: '80%'}} 
          >
             Get Free Quote 
        </Col>
      </Row>
      </Link>
      
    </>}
    </ConfigProvider>
  )
}
export default MoreSection;
