import React from 'react';
import { Outlet } from "react-router-dom";
import { Layout, ConfigProvider } from 'antd';
import { ReactComponent as WALogo } from '../Images/wa_logo.svg';
import NavigationBar from './TopNavigation/NavigationBar';
import '../App.css';

const { Header, Content, Footer } = Layout;

const Skeleton = () => {
  const encodedUrl = () => (`http://wa.me/${encodeURIComponent('+6585123494')}?`
    + `text=${'Hello, I\'m interested to know more about your products'}`)

  return (
    <ConfigProvider theme={{
      token: {
        fontFamily: `"Miriam Libre", sans-serif`,
      }
    }}>
    <Layout className="MainLayout">
      <Header className="navheader">
        <NavigationBar />
      </Header>
      <Content className="container">
        <div className="waBtn">
          <a href={encodedUrl()}>
          <WALogo className="waBtnLogo" />
          </a>
        </div>
        <div className="space">
        </div>
        <Outlet />
      </Content>
      <Footer className="footerbtm">
        Copyright 2023 Beema Scaffolding Singapore. All Rights Reserved.
      </Footer>
    </Layout>
    </ConfigProvider>
  );
}

export default Skeleton;