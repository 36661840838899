import { ReactComponent as PriceSvg } from '../../Images/12.svg';
import { ReactComponent as CertificateSvg } from '../../Images/13.svg';
import { ReactComponent as ToolsSvg } from '../../Images/14.svg';
import { ReactComponent as DurabilitySvg } from '../../Images/15.svg';

const contentList = [
  {
    Title: 'Family-Owned Manufacturing Excellence',
    Content: 'We take pride in our family-owned manufacturing assembly, Beema Infratech Private Limited, located in India. This familial connection ensures a level of trust, transparency, and commitment to the quality of our aluminium mobile scaffold that sets us apart. With complete control over the manufacturing process, we guarantee the highest standards of safety and excellence for our valued customers in Singapore.',
    Icon: <ToolsSvg className="svgIcon"/>,
    IconMbl: <ToolsSvg className="svgIconMbl"/>
  },
  {
    Title: 'Built for Safety and Compliance',
    Content: 'At Beema Scaffolding Singapore Pte Ltd, safety is paramount. Our aluminium mobile scaffolds are meticulously designed and configured to meet the stringent standards of BS EN 1004 and SS 659-2020 compliance. We take pride in providing our customers with scaffolds that not only meet industry standards but also exceed expectations for safety and reliability.',
    Icon: <CertificateSvg className="svgIcon"/>,
    IconMbl: <CertificateSvg className="svgIconMbl"/>
  },
  {
    Title: 'Competitive Pricing Advantage',
    Content: 'At Beema Scaffolding Singapore Pte Ltd, we offer cost-effective solutions that result from our unique position as both the manufacturer and supplier. With our in-house manufacturing facility in India, we eliminate middleman costs for our aluminium mobile scaffolds, ensuring you receive competitive pricing without compromising on quality.',
    Icon: <PriceSvg className="svgIcon"/>,
    IconMbl: <PriceSvg className="svgIconMbl"/>
  },
  {
    Title: 'Uncompromising Quality Assurance',
    Content: 'We take quality seriously. Our aluminium mobile scaffolds are meticulously crafted in our family-owned factory, Beema Infratech Private Limited, based in India. We exclusively use 6063 Aluminium Grade, renowned for its exceptional strength and durability, meticulously treated to T6 Temper. This commitment to premium materials and precise manufacturing is our promise of durability and reliability to our Singaporean customers.',
    Icon: <DurabilitySvg className="svgIcon"/>,
    IconMbl: <DurabilitySvg className="svgIconMbl"/>
  },
]

export {
  contentList
}