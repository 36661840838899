import React from 'react';
import {
  Form,
  Input,
} from 'antd';

const CustomiseInput = () => {

  return (
    <>
      <Form.Item
        style={{width: '100%'}}
        name="name"
        hasFeedback
        // validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please enter your name',
          },
          {
            pattern: /^[a-zA-Z][a-zA-Z ]{1,}$/,
            message: 'Please enter a valid name',
          },
        ]}
      >
        <Input placeholder="Name" />

      </Form.Item>
      <Form.Item
        style={{width: '100%'}}
        name="phone"
        hasFeedback
        rules={[
          { 
            required: true,
            message: 'Please enter your phone number' 
          },
          {
            pattern: /^[0-9]{8}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
      >
        <Input addonBefore="+65" placeholder="Phone Number" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        style={{width: '100%'}}
        name="email"
        hasFeedback
        rules={[
          {
            type: 'email',
            message: 'The input is not valid email',
          },
          {
            required: true,
            message: 'Please enter your email',
          },
        ]}
      >
        <Input placeholder="Email Address"/>
      </Form.Item>
      <Form.Item
        style={{width: '100%'}}
        name="companyName"
        hasFeedback
      >
        <Input placeholder="Company Name (optional)" />

      </Form.Item>
    </>
  )
}
export default CustomiseInput;