import React, { useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Row, Col, Form } from 'antd';
import { PushpinFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useViewport } from '../Components/Viewport';
import { ConfigProvider } from 'antd';
import CatalogueModal from '../Components/CatalogueModal';
import MoreSection from '../Components/MoreSection/MoreSection';
import BeemaScaffoldingSGCatalogue from '../Beema-Scaffolding-SG-Catalogue.pdf'

const ContactUs = ({mainpage}) => {
  const { screenWidth } = useViewport();
  const breakpoint = 760;
  const moreInfoBreakpoint = 760;
  const gmapLinkText = 'https://www.google.com/maps/dir/1.3601756,103.8850509/Beema+Scaffolding+Singapore,+102F+Pasir+Panjang+Rd,+%2304-12,+Singapore+-+118530,+Singapore+118530/@1.3601742,103.8026489,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x31da1babf131d1f5:0x8c60bc9ebe01f3c8!2m2!1d103.7954127!2d1.2760918?entry=ttu';
  const gmapLinkDirection = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8284821342795!2d103.79253697413554!3d1.2762981987115654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1babf131d1f5%3A0x8c60bc9ebe01f3c8!2sBeema%20Scaffolding%20Singapore!5e0!3m2!1sen!2sus!4v1695978296021!5m2!1sen!2sus';
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const modalText = 'Content of the modal';
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (values) => {
    setConfirmLoading(true);
    sendCatalogueEmail(values);
  };
  const downloadPDF = () => {
    const link = document.createElement('a');
    link.download = 'BeemaScaffoldingSG';
    link.href = BeemaScaffoldingSGCatalogue;
    link.click();
  };
  const sendCatalogueEmail = (formData) => {
    const currTimestamp = moment().format('DD-MM-YYYY HH:mm:ss').toString();
    const formDataFormatted = {
      ...formData,
      companyName: formData?.companyName ?? 'NA',
      timestamp: currTimestamp
    }
    Axios.post(
      'https://us-central1-beemascaffoldingsg.cloudfunctions.net/submitCatalogue',
      formDataFormatted
    )
      .then(res => {
        setConfirmLoading(false);
        downloadPDF();
        form.resetFields();
        setOpen(false);
      })
      .catch(error => {
        setConfirmLoading(false);
        downloadPDF();
        form.resetFields();
        setOpen(false);
      })
  }
  const handleCancel = () => {
    setOpen(false);
  };

  const contactDetails = () => (
    <Row justify="center" span={24}>
    <Col span={20}>
    <div style={{ paddingLeft: '10px', color: "black", fontSize: '15px', textAlign: "left"}}>
      <h2>Beema Scaffolding (Singapore) Pte. Ltd.</h2>
      <p>If you are interested in purchasing or renting our products, please drop a <Link to="/getQuote">quotation request</Link>. For scaffold viewing, please call <a href="tel:+6585123494">(+65) 8512 3494</a> before coming down to the warehouse.
      Drop a What'sApp message for urgent queries.</p>
      <p>
        <PushpinFilled style={{paddingRight: '10px'}} />
        <a href={gmapLinkText}>
          <b>Beema Scaffolding Singapore, 102F Pasir Panjang Rd, #04-12, Singapore - 118530</b>
        </a>
      </p>
      <p>
        <MailFilled style={{paddingRight: '10px'}} /><a href="mailto:contact@beemascaffolding.sg">
          <b>contact@beemascaffolding.sg</b></a>
      </p>
      <p>
        <PhoneFilled style={{paddingRight: '10px'}} />
        <a href="tel:+6585123494"><b>(+65) 8512 3494</b></a>
      </p>
    </div>
    </Col>
  </Row>
  )
  
  return (
    <div style={{backgroundColor: '#d4d6d9', padding: '10px'}}>
    <ConfigProvider
      theme={{
        token: {
          colorLink: '#a20',
          colorLinkActive: 'black',
          colorLinkHover: 'black'
        },
      }} >
      <CatalogueModal open={open} confirmLoading={confirmLoading}
        modalText={modalText} form={form} showModal={showModal} handleOk={handleOk}
        handleCancel={handleCancel} />
      {(!mainpage) ? <h1 style={{color: "black"}} >Contact Us</h1> :''}
      {(screenWidth > breakpoint) ?
      <Row justify="center">
        <Col span={12}>
          {contactDetails()}
          {(!mainpage) ? 
          <Row justify="center" span={24}>
            <Col span={24}>
              <MoreSection screenWidth={screenWidth} breakpoint={moreInfoBreakpoint} showModal={showModal}/>
            </Col>
          </Row> : ''}
        </Col>
        <Col span={10}>
          <iframe title="desktop" src={gmapLinkDirection}
            width="100%" height="350" style={{border:0, borderRadius: '10px'}}
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </Col>
      </Row> :
      <div>
      <Row justify="center">
        <Col span={24}>
        {contactDetails()}
        {(!mainpage) ? 
        <Row justify="center" span={24}>
          <Col span={24}>
            <MoreSection screenWidth={screenWidth} breakpoint={moreInfoBreakpoint} showModal={showModal}/>
          </Col>
        </Row>
        :''}
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <iframe title="desktop" src={gmapLinkDirection}
            width="100%" height="350" style={{border:0, borderRadius: '10px'}}
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </Col>
      </Row>
    </div>}
    </ConfigProvider>
    </div>
  )
};

export default ContactUs;