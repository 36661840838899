import React from 'react';
import { Link } from "react-router-dom";
import RightMenu from './RightMenu'
import { Drawer, Button, Row, Col } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useViewport } from '../Viewport';
import logo from '../../Images/logo_img.png';

const NavigationBar = () => {
  const [visible, setVisible] = React.useState(false);
  const { screenWidth } = useViewport();
  const mobileBreakpoint = 820;
  const ipadBreakpoint = 1067;
  const smallestBreakpoint= 340;

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const desktop = () => {
    return (
      <>
        <RightMenu layout={'desktop'} type="horizontal"/>
      </>
    )
  }

  const getTitleWidth = () => {
    if (screenWidth >= ipadBreakpoint) {
      return '11';
    } else if (screenWidth >= mobileBreakpoint) {
      return '6';
    } else {
      return '17';
    }
  };
  const getNavigationbarWidth = () => {
    if (screenWidth >= mobileBreakpoint) {
      return '16';
    } else {
      return '5';
    }
  };

  const mobile = () => {
    return (
      <div className="right-menu-mobile" style={{right: '54px'}} >
        <Button  type="primary" onClick={showDrawer} style={{color: '#a20000', borderColor: '#a20000', float: 'right', border: '2px solid', padding: '10px 10px 28px 10px'}} ghost>
          <MenuOutlined style={{fontSize: '20px', border: '20px'}} />
        </Button>
        <Drawer
          title="Menu"
          placement="right"
          closable={true}
          onClose={onClose}
          open={visible}
        >
          <RightMenu layout={'mobile'} onClose={onClose} type="inline" />
        </Drawer>
      </div>
    )
  }
  return (
    <>
      <Row align='middle' span={24} style={{width: '100%', overflow: 'hidden'}} >
      <Link to="/"><Col flex="2" ><img src={logo} alt='aluminium mobile scaffold' className="logo" style={{float: 'left'}}></img></Col></Link>
        {(screenWidth >= smallestBreakpoint) ? <Col flex={getTitleWidth()} ><Link to="/"><div className="companyFont" style={(screenWidth < ipadBreakpoint) ? {fontSize: '15px', lineHeight: '25px'} :{fontSize: '2vw', lineHeight: '2vw'}}><b>BEEMA SCAFFOLDING (SG) PTE LTD</b></div></Link></Col>: <Col></Col>}
        <Col flex={getNavigationbarWidth()} >{screenWidth < mobileBreakpoint ? mobile() : desktop()}</Col>
      </Row>
    </>
  )
}

export default NavigationBar;