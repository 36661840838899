import React, { useRef, useState} from 'react';
import Axios from 'axios';
import moment from 'moment';
import { DownCircleOutlined } from '@ant-design/icons';
import { Col, Row, Form } from 'antd';
import { useViewport } from '../Components/Viewport';
import KeyPoints from '../Components/KeyPoints/KeyPoints';
import MoreSection from '../Components/MoreSection/MoreSection';
import ContactUs from './ContactUs';
import Services from '../Components/Services/Services'
import Products from '../Components/Products';
import CatalogueModal from '../Components/CatalogueModal';
import beemascaffold from '../Images/beemascaffold.jpeg'
import beemascaffold2 from '../Images/beemascaffold2.jpeg'
import BeemaScaffoldingSGCatalogue from '../Beema-Scaffolding-SG-Catalogue.pdf'
import '../App.css';

const Home = () => {
  const scollToRef = useRef();
  const { screenWidth, screenHeight } = useViewport();
  const breakpoint = 760;
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (values) => {
    setConfirmLoading(true);
    sendCatalogueEmail(values);
  };
  const downloadPDF = () => {
    const link = document.createElement('a');
    link.download = 'BeemaScaffoldingSG';
    link.href = BeemaScaffoldingSGCatalogue;
    link.click();
  };
  const sendCatalogueEmail = (formData) => {
    const currTimestamp = moment().format('DD-MM-YYYY HH:mm:ss').toString();
    const formDataFormatted = {
      ...formData,
      companyName: formData?.companyName ?? 'NA',
      timestamp: currTimestamp
    }
    Axios.post(
      'https://us-central1-beemascaffoldingsg.cloudfunctions.net/submitCatalogue',
      formDataFormatted
    )
      .then(res => {
        setConfirmLoading(false);
        downloadPDF();
        form.resetFields();
        setOpen(false);
      })
      .catch(error => {
        setConfirmLoading(false);
        downloadPDF();
        form.resetFields();
        setOpen(false);
      })
  }
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <CatalogueModal open={open} confirmLoading={confirmLoading}
        form={form} showModal={showModal} handleOk={handleOk}
        handleCancel={handleCancel} />
      <div>
        <div style={{
          backgroundImage: (screenWidth > 760) ? `url(${beemascaffold})` : `url(${beemascaffold2})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: (screenWidth > 1035) ? screenHeight-150 : screenHeight-100,
          position: 'relative',
          textAlign: 'center'
        }} >
          <div className="filter"  >
            <div className="textDiv" >
              <div className="mainText">
                ALUMINIUM MOBILE SCAFFOLD SPECIALIST
              </div>
              <br />
              <div className="subText">
                Beema Scaffolding (Singapore) Pte Ltd is a trusted 
                movable aluminium scaffolding system provider based in Singapore.
              </div>
            </div>
            <div className="downBtn" ref={scollToRef}>
              <DownCircleOutlined
                className="downBtnIcon"
                onClick={() => scollToRef.current.scrollIntoView({behavior: 'smooth' })} />
            </div>
          </div>
        </div>
        <Row style={{paddingTop: '30px'}}>
          <Col className="secondMainText" span={24} >
            Our Products
          </Col>
        </Row>
        <Row style={{ color: 'black', textAlign: "justify"}} justify="center">
          <Col span={20}  >
            <p style={{ fontSize: '15px'}}>Made in India, Beema Scaffolding’s Aluminium mobile scaffold access tower system has designed to be mobile and portable and multi-purpose and lightweight have other versatile applications for overhead works. Singapore’s first  Scaffolding Company to have an affiliation with an overseas manufacturing assembly in Aluminium Mobile Tower Scaffolding.</p>
          </Col>
        </Row>
        <Row style={{paddingBottom: '30px'}} justify="center">
            <Products screenWidth={screenWidth} breakpoint={breakpoint} />
        </Row>
        <Row style={{paddingTop: '30px', backgroundColor: '#eeece6'}}>
          <Col className="secondMainText" span={24} >
            Why choose us?
          </Col>
        </Row>
        <div style={{backgroundColor: '#eeece6', paddingBottom: '30px'}}>
          <KeyPoints />
        </div>
        <Row style={{backgroundColor: 'white', paddingTop: '30px'}}>
          <Col className="secondMainText" span={24} >
            What we offer?
          </Col>
        </Row>
        <Row style={{ backgroundColor: 'white', color: 'black', textAlign: "justify"}} justify="center">
          <Col span={20}  >
            <p style={{ fontSize: '15px'}}>We provide a one stop solution for both sales and rent of aluminium mobile scaffolds. Our team also takes care of installation & dismantling, certifying of scaffolds and transportation ensuring a stress-free experience.</p>
          </Col>
        </Row>
        <Row style={{backgroundColor: 'white'}}>
          <Col span={24} justify="center">
            <Services screenWidth={screenWidth} breakpoint={breakpoint} />
          </Col>
        </Row>
        <Row style={{paddingTop: '30px'}}>
          <Col className="secondMainText" span={24} >
            Find out more
          </Col>
        </Row>
        <Row style={{paddingBottom: '20px'}}>
          <Col span={24}>
            <MoreSection
              screenWidth={screenWidth}
              breakpoint={breakpoint}
              showModal={showModal} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ContactUs mainpage={true} />
          </Col>
        </Row>
      </div>
    </>
  )
};

export default Home;