import React from 'react';
import {
  Button, Form, InputNumber,
  Radio, Row, Col, Tooltip, 
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const ProductDetailsDesktop = ({ isSingleWidth, toggleWidth }) => {

  return (
    <>
      <Form.Item
        label="Select product details:"
        style={{marginBottom: 0, marginTop: '10px'}}
        rules={[
        ]}
        >
        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} 
                  style={{border: '1px dashed #a1a1a1', borderBottom: 0, marginBottom: 0, padding: '10px 10px 0px 10px', borderRadius: '6px 6px 0px 0px'}}
                  span={24} justify="space-between" align="bottom">
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label="Working Height"
                      name={[name, 'height']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing height',
                        },
                      ]}
                    >
                      <InputNumber
                        step="0.1"
                        style={{float: "left" }}
                        min={2} max={15}
                        placeholder="e.g. 3.5"
                        addonAfter="meter" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      {...restField}
                      name={[name, 'width']}
                      label="Width"
                      style={{float: "left", width: '100%'}}
                      rules={[{ required: true, message: 'Missing width' }]}
                    >
                      <Radio.Group style={{width: '90%'}} >
                        <Tooltip title="single width: 0.80m">
                          <Radio.Button 
                            style={{ width: '50%', paddingLeft: '5px', paddingRight: '5px' }
                              // (isSingleWidth)
                              //   ?{width: '50%', paddingLeft: '5px', paddingRight: '5px', fontWeight: '600'}
                              //   :{ width: '50%', paddingLeft: '5px', paddingRight: '5px' }
                            }
                            onClick={toggleWidth}
                            value="single">
                            Single
                          </Radio.Button>
                        </Tooltip>
                        <Tooltip title="double width: 1.35m">
                          <Radio.Button
                            style={{ width: '50%', paddingLeft: '5px', paddingRight: '5px' }
                              // (!isSingleWidth)
                              //   ?{width: '50%', paddingLeft: '5px', paddingRight: '5px', fontWeight: '600'}
                              //   :{ width: '50%', paddingLeft: '5px', paddingRight: '5px' }
                            }
                            onClick={toggleWidth}
                            value="double">
                            Double
                          </Radio.Button>
                        </Tooltip>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      rules={[{ required: true, message: 'Missing Quantity' }]}
                      label="Quantity"
                      name={[name, 'quantity']}
                    >
                        <InputNumber style={{width: '100%'}} placeholder={'e.g. 1'}  min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {
                      (key === 0)
                        ? 
                        <Form.Item style={{float:"right"}} label="">
                          <Tooltip title="">
                            <DeleteOutlined style={{color: 'grey', fontSize: "18px", cursor: 'not-allowed'}} />
                          </Tooltip>
                        </Form.Item>
                        :
                        <Form.Item style={{float:"right"}} label="">
                          <Tooltip title="delete product">
                            <DeleteOutlined style={{color: 'white', fontSize: "18px"}} onClick={() => remove(name)} />
                          </Tooltip>
                        </Form.Item>
                    }
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button  onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Product
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  )
}
export default ProductDetailsDesktop;