import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ViewportProvider } from './Components/Viewport'
import Skeleton from './Components/Skeleton';
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import GetQuote from "./Pages/GetQuote";
import NoPage from "./Pages/NoPage";
import ScrollToTop from './ScrollToTop';
import './App.css';

const App = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);
  return (
    <BrowserRouter className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ViewportProvider>
          <Skeleton />
        </ViewportProvider>} >
          <Route index element={<Home />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="getQuote" element={<GetQuote />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;